import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ textAlign: "center", marginTop: "40px" }}>
      <h1>NOT FOUND</h1>
      <p>Dunno how y'all ended up here</p>
    </div>
  </Layout>
)

export default NotFoundPage
